










































































































































































@import "../../../../assets/css/exper/experiDetail";

.tabsCage {
  margin-left: 0;
}
